import { wallet } from "@fscrypto/domain";
import { walletSchema } from "@fscrypto/domain/wallet";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";
import { z } from "zod";

export class WalletClient {
  constructor(private client: HttpClient) {}

  async get() {
    return this.client.get($path("/api/wallet"), walletSchema);
  }

  async upsert(payload: wallet.AddressUpsert) {
    return await this.client.post(
      $path("/api/wallets/add"),
      payload,
      z.object({ newAddress: wallet.addressSchema, wallet: wallet.walletSchema }),
    );
  }

  async delete(id: string) {
    await this.client.post($path("/api/wallets/:id/delete", { id }), {});
    return;
  }
}

export const walletClient = new WalletClient(new Client());
