import { wallet } from "@fscrypto/domain";
import { Entity, EntityFactory, EventBus, Store, createStore, useEntity, useStore } from "@fscrypto/state-management";
import { groupBy } from "lodash-es";
import { useObservable, useObservableState } from "observable-hooks";
import { useMemo } from "react";
import { combineLatest, filter, map, switchMap } from "rxjs";
import { GlobalEvent, eventBus } from "~/state/events";
import { WalletClient, walletClient } from "../data/wallet-client";

class WalletAddressEntity implements Entity<wallet.Address> {
  public readonly id: string;
  store: Store<wallet.Address>;
  constructor(
    wallet: wallet.Address,
    private eventBus: EventBus<GlobalEvent>,
    private client: WalletClient,
  ) {
    this.id = wallet.id;
    this.store = createStore(wallet);
  }

  async update(address: wallet.AddressUpsert) {
    this.eventBus.send({ type: "GLOBAL.WALLET.UPSERT_IN_PROGRESS", payload: { addressUpsert: address } });
    try {
      const res = await this.client.upsert(address);
      this.store.set(res.newAddress);
      this.eventBus.send({ type: "GLOBAL.WALLET.UPSERT_SUCCESS", payload: res });
    } catch (e) {
      this.eventBus.send({ type: "GLOBAL.WALLET.UPSERT_FAILURE", payload: { error: e as Error } });
      throw e;
    }
  }

  async setDefault() {
    const wa = this.store.get();
    await this.update({ chain: wa.chain, address: wa.address, isDefault: true });
    this.eventBus.send({ type: "GLOBAL.WALLET.SET_DEFAULT_SUCCESS", payload: { address: wa } });
  }

  async setNickname(nickname: string) {
    const wa = this.store.get();
    await this.update({ chain: wa.chain, address: wa.address, nickname });
  }

  async delete() {
    const wa = this.store.get();
    await this.client.delete(this.id);
    this.eventBus.send({ type: "GLOBAL.WALLET.DELETE_SUCCESS", payload: { address: wa } });
  }
}

class WalletAddressFactory implements EntityFactory<WalletAddressEntity> {
  store: Store<WalletAddressEntity[]>;
  constructor(
    private eventBus: EventBus<GlobalEvent>,
    private client: WalletClient,
  ) {
    this.store = createStore([] as WalletAddressEntity[]);
    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "GLOBAL.WALLET.UPSERT_SUCCESS":
          this.store.set(e.payload.wallet.map((w) => new WalletAddressEntity(w, this.eventBus, this.client)));
          if (e.payload.newAddress.isDefault) {
            this.eventBus.send({
              type: "GLOBAL.WALLET.SET_DEFAULT_SUCCESS",
              payload: { address: e.payload.newAddress },
            });
          }
          break;
        case "GLOBAL.WALLET.DELETE_SUCCESS":
          this.store.set(this.store.get().filter((w) => w.id !== e.payload.address.id));
          break;
      }
    });
  }

  async initialize() {
    const res = await this.client.get();
    this.store.set(res.map((w) => new WalletAddressEntity(w, this.eventBus, this.client)));
  }

  async upsert(address: wallet.AddressUpsert) {
    const existingEntity = this.store
      .get()
      .find((w) => w.store.get().address === address.address && w.store.get().chain === address.chain);
    if (existingEntity) {
      existingEntity.update(address);
    } else {
      const res = await this.client.upsert(address);
      if (res.newAddress.isDefault) {
        this.eventBus.send({ type: "GLOBAL.WALLET.SET_DEFAULT_SUCCESS", payload: { address: res.newAddress } });
      }
      this.store.set([...this.store.get(), new WalletAddressEntity(res.newAddress, this.eventBus, this.client)]);
    }
  }

  from(walletAddress: wallet.Address) {
    const existing = this.store.get().find((w) => w.id === walletAddress.id);
    if (existing) {
      return existing;
    }
    const walletEntity = new WalletAddressEntity(walletAddress, this.eventBus, this.client);
    this.store.set([...this.store.get(), walletEntity]);
    return walletEntity;
  }

  from$(id: string) {
    return this.store.value$.pipe(
      map((wallets) => wallets.find((wallet) => wallet.id === id)),
      filter(Boolean),
    );
  }

  fromAll$() {
    return this.store.value$.pipe(
      map((w) => w.map((w) => w.store)),
      filter(Boolean),
    );
  }
}

export const walletFactory = new WalletAddressFactory(eventBus, walletClient);

export const useWalletAddress = (id: string) => {
  const entity = useEntity(walletFactory, id);

  if (!entity) {
    return {} as const;
  }

  return {
    entity,
    update: entity.update.bind(entity),
    setDefault: entity.setDefault.bind(entity),
    setNickname: entity.setNickname.bind(entity),
    delete: entity.delete.bind(entity),
  };
};

let initialized = false;
const loadingStore = createStore<boolean>(false);
export const useWallets = () => {
  const isLoading = useStore(loadingStore);
  const stores$ = useObservable(() => walletFactory.fromAll$());
  const values$ = useObservable(() => stores$.pipe(switchMap((ss) => combineLatest(ss.map((s) => s.value$)))));
  const wallets = useObservableState(values$, []);

  const walletsByChain = useMemo(() => groupBy(wallets, "chain"), [wallets]);
  const verifiedWallets = useMemo(() => wallets.filter((w) => w.isVerified), [wallets]);
  const verifiedWalletsByChain = useMemo(() => groupBy(verifiedWallets, "chain"), [verifiedWallets]);
  const defaultWalletByChain = useMemo(() => {
    const defaultWalletByChain: Record<string, wallet.Address> = {};
    wallets.forEach((w) => {
      if (w.isVerified && w.isDefault) defaultWalletByChain[w.chain] = w;
    });
    return defaultWalletByChain;
  }, [wallets]);

  const initialize = async () => {
    const loading = loadingStore.get() === true;
    const isWalletStoreEmpty = walletFactory.store.get().length === 0;
    const shouldInitialize = isWalletStoreEmpty && !initialized && !loading;
    if (shouldInitialize) {
      loadingStore.set(true);
      await walletFactory.initialize();
      loadingStore.set(false);
      initialized = true;
    }
  };

  return {
    wallets,
    verifiedWallets,
    walletsByChain,
    verifiedWalletsByChain,
    defaultWalletByChain,
    isLoading,
    upsert: walletFactory.upsert.bind(walletFactory),
    initialize,
  };
};

export type GlobalWalletUpsertInProgressEvent = {
  type: "GLOBAL.WALLET.UPSERT_IN_PROGRESS";
  payload: {
    addressUpsert: wallet.AddressUpsert;
  };
};

export type GlobalWalletUpsertSuccessEvent = {
  type: "GLOBAL.WALLET.UPSERT_SUCCESS";
  payload: {
    newAddress: wallet.Address;
    wallet: wallet.Wallet;
  };
};

export type GlobalWalletUpsertFailureEvent = {
  type: "GLOBAL.WALLET.UPSERT_FAILURE";
  payload: {
    error: Error;
  };
};

export type GlobalWalletSetDefaultSuccessEvent = {
  type: "GLOBAL.WALLET.SET_DEFAULT_SUCCESS";
  payload: {
    address: wallet.Address;
  };
};

export type GlobalWalletDeleteSuccessEvent = {
  type: "GLOBAL.WALLET.DELETE_SUCCESS";
  payload: {
    address: wallet.Address;
  };
};
